.main-container {
  background-color: #FAF6F5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

p {
  font-family: "DM Sans", sans-serif;
}

span {
  font-family: "DM Sans", sans-serif;
}