.form-container {
  width: 80%;
  max-width: 80%;
  background-color: #fff;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 35px 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.checkBox-container {
  width: 300px;
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

.checkBox-container label {
  font-size: 11px;
  font-weight: 400;
  line-height: 19.5px;
  color: #5E596D;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 12px;
}

.input {
  padding-left: 17px;
  margin-bottom: 15px;
  background-color: #FAF6F5;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  height: 48px;
  max-width: 283px;
  width: 283px;
}

.input::placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #A7A7A7;
  font-weight: 600;
}

button {
  background-color: #fff;
  border: 1px solid #DA3831;
  width: 236px;
  max-width: 236px;
  height: 54px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  justify-content: center;
  display: flex;
  margin-top: 44px;
}

button p {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #DA3831;
  line-height: 18.23px;
}

button:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: #FAF6F5;
  cursor: not-allowed;
}

button:disabled p{
  color: #FAF6F5;
  cursor: not-allowed;
}
button:disabled:hover  {
  background-color: gray;
  color: #FAF6F5;
  cursor: not-allowed;
}

button:hover {
  background-color: #B8302A;
  color: #FAF6F5;
  cursor: pointer;
}

button:hover p {
  color: #FAF6F5;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .form-container {
    width: 100%;
    padding: 25px;
  }

  .input {
    height: 45px;
    font-size: 14px;
  }

  button {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .input {
    height: 42px;
    font-size: 13px;
  }

  .checkBox-container label {
    font-size: 10px;
  }

  button {
    height: 50px;
    font-size: 12px;
  }
}